import React, { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';

const Layout: FC = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const onToggleClick = () => {
    setToggleSidebar(!toggleSidebar);
  };

  return (
    <div className="App-wrapper">
      <div>
        <Sidebar isCollapse={toggleSidebar} />
      </div>
      <div className={`layout-padding${toggleSidebar ? ' active-sidebar-panel' : ''}`}>
        <Header toggleHandler={onToggleClick} />
        <Outlet />
      </div>
    </div>
  );
};
export default Layout;

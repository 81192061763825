/* eslint-disable no-unreachable */
import React, { FC, Suspense, lazy } from 'react';
// import Login from './pages/auth/Login';
import { Route, Routes } from 'react-router-dom';
import { Preloader } from './components/preloader/Preloader';
import { UIRoutes } from './constants/FrontendRoutes';
import Layout from './layouts/Layout';
// import Login from './pages/auth/Login';
// import ComingSoon from './pages/coming-soon/ComingSoon';
// import Dashboard from './pages/dashboard/Dashboard';
import EndTreatmentTable from './pages/end-treatment/EndTreatmentTable';
// import SimulationView from './pages/simulation/SimulationView';
// import SimulationCreate from './pages/simulation/simulation-create/SimulationCreate';
// import SimulationCreateWithData from './pages/simulation/simulation-create/SimulationCreateWithData';
// import UserProfile from './pages/user-profile/UserProfile';
// import WorkListTable from './pages/worklist/WorkListTable';
// import ChartRoute from './routes/ChartRoute';
// import ConfigurationRoutes from './routes/ConfigurationRoutes';
// import EndTreatmentRoute from './routes/EndTreatmentRoute';
// import OnTreatmentRoutes from './routes/OnTreatmentRoute';
// import PatientRoutes from './routes/PatientRoutes';
// import PatientTrackerRoutes from './routes/PatientTrackerRoutes';
// import TreatmentPlanningRoutes from './routes/TreatmentPlanningRoutes';
import './scss/_styles.scss';

const Login = lazy(() => import('./pages/auth/Login'));
const ComingSoon = lazy(() => import('./pages/coming-soon/ComingSoon'));
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const SimulationView = lazy(() => import('./pages/simulation/SimulationView'));
const SimulationCreate = lazy(() => import('./pages/simulation/simulation-create/SimulationCreate'));
const SimulationCreateWithData = lazy(() => import('./pages/simulation/simulation-create/SimulationCreateWithData'));
const UserProfile = lazy(() => import('./pages/user-profile/UserProfile'));
const WorkListTable = lazy(() => import('./pages/worklist/WorkListTable'));
const ChartRoute = lazy(() => import('./routes/ChartRoute'));
const ConfigurationRoutes = lazy(() => import('./routes/ConfigurationRoutes'));
const EndTreatmentRoute = lazy(() => import('./routes/EndTreatmentRoute'));
const OnTreatmentRoutes = lazy(() => import('./routes/OnTreatmentRoute'));
const PatientRoutes = lazy(() => import('./routes/PatientRoutes'));
const PatientTrackerRoutes = lazy(() => import('./routes/PatientTrackerRoutes'));
const TreatmentPlanningRoutes = lazy(() => import('./routes/TreatmentPlanningRoutes'));

const App: FC = () => {
  // return <Preloader />;
  return (
    <div className="App">
      <Routes>
        <Route
          path={UIRoutes.LOGIN}
          element={
            <Suspense fallback={<Preloader />}>
              <Login />
            </Suspense>
          }
        />
        <Route path={UIRoutes.DASHBOARD} element={<Layout />}>
          <Route
            index
            element={
              <Suspense fallback={<Preloader />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path={`${UIRoutes.PRE_TREATMENT.INDEX}/*`}
            element={
              <Suspense fallback={<Preloader />}>
                <PatientTrackerRoutes />
              </Suspense>
            }
          />
          <Route
            path={`${UIRoutes.SIMULATION.CREATE}`}
            element={
              <Suspense fallback={<Preloader />}>
                <SimulationCreate />
              </Suspense>
            }
          />
          <Route
            path={`${UIRoutes.SIMULATION.VIEW}`}
            element={
              <Suspense fallback={<Preloader />}>
                <SimulationView />
              </Suspense>
            }
          />
          <Route
            path={`${UIRoutes.SIMULATION.EDIT}`}
            element={
              <Suspense fallback={<Preloader />}>
                <SimulationCreate />
              </Suspense>
            }
          />
          <Route
            path={`${UIRoutes.CONFIGURATION.INDEX}/*`}
            element={
              <Suspense fallback={<Preloader />}>
                <ConfigurationRoutes />
              </Suspense>
            }
          />
          <Route
            path={`${UIRoutes.TREATMENT_PLANNING.INDEX}/*`}
            element={
              <Suspense fallback={<Preloader />}>
                <TreatmentPlanningRoutes />
              </Suspense>
            }
          />
          <Route
            path={`${UIRoutes.PATIENT.INDEX}/*`}
            element={
              <Suspense fallback={<Preloader />}>
                <PatientRoutes />
              </Suspense>
            }
          />
          <Route
            path={`${UIRoutes.ON_TREATMENT.INDEX}/*`}
            element={
              <Suspense fallback={<Preloader />}>
                <OnTreatmentRoutes />
              </Suspense>
            }
          />
          <Route
            path={`${UIRoutes.END_TREATMENT.INDEX}/*`}
            element={
              <Suspense fallback={<Preloader />}>
                <EndTreatmentRoute />
              </Suspense>
            }
          />
          <Route
            path={`${UIRoutes.CHART.INDEX}/*`}
            element={
              <Suspense fallback={<Preloader />}>
                <ChartRoute />
              </Suspense>
            }
          />
          <Route
            path={UIRoutes.USER_PROFILE.INDEX}
            element={
              <Suspense fallback={<Preloader />}>
                <UserProfile />
              </Suspense>
            }
          />
          <Route
            path={UIRoutes.WORK_LIST.INDEX}
            element={
              <Suspense fallback={<Preloader />}>
                <WorkListTable />
              </Suspense>
            }
          />
          <Route
            path={UIRoutes.COMING_SOON.INDEX}
            element={
              <Suspense fallback={<Preloader />}>
                <ComingSoon />
              </Suspense>
            }
          />
          <Route
            path={UIRoutes.SIMULATIONWITHDATA.INDEX}
            element={
              <Suspense fallback={<Preloader />}>
                <SimulationCreateWithData />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </div>
  );
};

export default App;

export const UIRoutes = {
  DASHBOARD: '/',
  LOGIN: '/login',
  PRE_TREATMENT: {
    INDEX: '/pre-treatment',
    VIEW: 'view',
  },
  SIMULATION: {
    INDEX: '/simulation',
    CREATE: '/simulation/create',
    VIEW: '/simulation/view',
    EDIT: '/simulation/edit',
  },
  SIMULATIONWITHDATA: {
    INDEX: '/edit-simulation',
  },
  CONFIGURATION: {
    INDEX: '/configuration',
    SIMULATION_ORDER: 'simulation-order',
  },
  TREATMENT_PLANNING: {
    INDEX: '/treatment-planning',
    CREATE: 'create',
    VIEW: 'view',
  },
  PATIENT: {
    INDEX: '/patient-list',
    VIEW: 'view',
    CREATE_MANUAL: 'manual-create',
    SEARCH_ENTRY: 'search-entry',
  },
  USER_PROFILE: {
    INDEX: '/user-profile',
  },
  ON_TREATMENT: {
    INDEX: '/on-treatment',
    CREATE: 'create',
    VIEW: 'view',
  },
  END_TREATMENT: {
    INDEX: '/end-treatment',
    CREATE: 'create',
    VIEW: 'view',
  },
  WORK_LIST: {
    INDEX: '/worklist',
  },
  COMING_SOON: {
    INDEX: '/coming-soon',
  },
  CHART: {
    INDEX: '/chart',
    VIEW: 'view',
  },
};

export const HeaderModulesIcons = {
  [UIRoutes.DASHBOARD]: {
    moduleName: 'Dashboard',
  },
  [UIRoutes.SIMULATION.INDEX]: {
    moduleName: 'Simulation',
  },
  [UIRoutes.PATIENT.INDEX]: {
    moduleName: 'Patient',
  },
  [UIRoutes.CONFIGURATION.INDEX]: {
    moduleName: 'Configuration',
  },
  [UIRoutes.PRE_TREATMENT.INDEX]: {
    moduleName: 'Pre Treatment',
  },
  [UIRoutes.TREATMENT_PLANNING.INDEX]: {
    moduleName: 'Treatment Planning',
  },
  [UIRoutes.ON_TREATMENT.INDEX]: {
    moduleName: 'On Treatment',
  },
  [UIRoutes.USER_PROFILE.INDEX]: {
    moduleName: 'User Profile',
  },
  [UIRoutes.END_TREATMENT.INDEX]: {
    moduleName: 'End Treatment',
  },
  [UIRoutes.WORK_LIST.INDEX]: {
    moduleName: 'Work List',
  },
  [UIRoutes.CHART.INDEX]: {
    moduleName: 'Chart',
  },
  [UIRoutes.COMING_SOON.INDEX]: {
    moduleName: 'Coming Soon',
  },
};

export const ActiveModule = {
  [UIRoutes.CONFIGURATION.SIMULATION_ORDER]: {
    moduleName: 'Simulation Order',
  },
};

import React, { FC } from 'react';

export const Preloader: FC = () => {
  return (
    <div id="loader-wrapper">
      <div id="loader" />

      {/* <div className="loader-section section-left" />
      <div className="loader-section section-right" /> */}
    </div>
  );
};
